import * as React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const CourseDetails = ({ data }) => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  React.useEffect(() => {
    const loggedIn = localStorage.getItem("details");
    if (loggedIn) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <main>
      <section className="work-details section-padding">
        <div className="container">
          <div className="row lg-marg">
            <div className="col-lg-6">
              <div className="cont md-mb40">
                <h6 className="mb-15">// Course Deails</h6>
                <h2 className="fw-700">{data.frontmatter.name}</h2>
                <div className="text mt-25">
                  <p>{data.frontmatter.desc}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img
                src={
                  data.frontmatter.image.childImageSharp.gatsbyImageData.images
                    .fallback.src
                }
                alt="img"
              />
            </div>
          </div>
        </div>
      </section>

      {isLoggedIn ? (
        <section className="video-links">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h6 className="mb-15">// Video Links</h6>
                <h2 className="fw-700">Watch the course videos!</h2>
                <div className="text mt-25">
                  <p>
                    These are pre recorded videos of the course. Watch them to
                    get a better understanding of the course.
                  </p>
                </div>
              </div>
            </div>
            <div className="row video-row">
              <div className="col-lg-12">
                <Accordion allowZeroExpanded>
                  {data.frontmatter.videoLinks.map((video, index) => (
                    <AccordionItem key={index} className="video-item">
                      <AccordionItemHeading>
                        <AccordionItemButton>{video.title}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <iframe
                          className="video-iframe"
                          width="100%"
                          src={video.link}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}
    </main>
  );
};

export default CourseDetails;
